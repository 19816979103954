import { gsap } from "gsap";
import { Power4 } from "gsap";
import lottie from 'lottie-web';
import enterLoader from './../../img/enter-loader.json';
import pagesLoader from './../../img/pages-loader.json';

export default class WelcomeLoading {

  constructor() {

    WelcomeLoading.enterLoader = lottie.loadAnimation({
      container: document.getElementById('js-enter-loader-logo'),
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: enterLoader
    });

    WelcomeLoading.pagesLoader = lottie.loadAnimation({
      container: document.getElementById('js-pages-loader-logo'),
      renderer: 'svg',
      autoplay: false,
      loop: true,
      animationData: pagesLoader
    });

    WelcomeLoading.enterLoader.setSpeed(2);
    WelcomeLoading.pagesLoader.setSpeed(2);

    WelcomeLoading.enterLoader.onComplete = function(){
      gsap.to(document.getElementById('js-welcome-loading'), {
        scaleY: 0,
        duration: .8,
        ease: Power4.easeInOut,
        onComplete: function() {
          document.getElementById('js-welcome-loading').classList.remove('origin-top');
          document.getElementById('js-welcome-loading').classList.add('origin-bottom');

          document.getElementById('js-enter-loader-logo').classList.add('hidden');
          document.getElementById('js-pages-loader-logo').classList.remove('hidden');

          WelcomeLoading.enterLoader.destroy();
        }
      });
    }

  }

}
