import * as THREE from 'three/build/three.module.js';
import emptyPoster from './../images/emptyPoster.png';
import 'nouislider/distribute/nouislider.css';
import {gsap} from "gsap";
import fitText from "FitText-UMD";

export default class Poster {

  constructor() {

    this.init = true;
    this.ratio = 1.4142857143;
    this.width = document.getElementById('poster-container').clientWidth;
    this.height = this.width * this.ratio;
    //this.width = 2480 / 5.5;
    //this.height = 3508 / 5.5;
    this.xAxis = 0;
    this.yAxis = 0;
    this.intensity = .2;
    this.speed = 5;
    this.stopAnimate = false;

    fitText( document.getElementById("posterMemberName"), 0.6 );

    this.resizeHandler = this.resize.bind(this);

    window.addEventListener('resize', this.resizeHandler);

  }

  resize(){
    this.width = document.getElementById('poster-container').offsetWidth;
    this.height = this.width * this.ratio;

    document.getElementById('poster-container').style.height = this.height+'px';
    document.getElementById('poster-content').style.height = this.height+'px';
    document.getElementById('flag').style.height = this.height+'px';


    if ( this.renderer){
      this.renderer.setSize(this.width, this.height);
    }

  }

  stop(){
    this.stopAnimate = true;
  }

  start(){
    this.stopAnimate = false;
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      45,  //fov — Camera frustum vertical field of view.
      this.width / this.height, //aspect — Camera frustum aspect ratio.
      0.1,  //near — Camera frustum near plane.
      100 //far — Camera frustum far plane.
    );

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      canvas: document.querySelector('canvas#flag'),
      preserveDrawingBuffer: true
    });



    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x000000, 1);

    const loader = new THREE.TextureLoader()
    const geometry = new THREE.PlaneGeometry(5, 5 * (this.height / this.width), 100, 10 * (this.height / this.width) * 10)
    let material = new THREE.MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      map: loader.load(emptyPoster, () => {
        material.opacity = 1
      }),
    });


    this.material = material;

    this.material = material;

    this.flag = new THREE.Mesh(geometry, this.material)
    this.flag.rotation.set(0, 0, 0);

    this.scene.add(this.flag);
    this.camera.position.z = 12;

    this.clock = new THREE.Clock();

    gsap.fromTo( document.querySelector('canvas#flag'), {opacity: 0},  { duration:2,  opacity: 1 });

    this.animate();

  }



  changeCover(cover) {

    gsap.to( document.querySelector('canvas#flag'), { duration:.4,  opacity: 0, onComplete:function(){
      this.flag.material.map = new THREE.TextureLoader().load(cover, function ( texture ) {
          gsap.to( document.querySelector('canvas#flag'),  {  duration:1,  opacity: 1 });
      });
    }.bind(this)});


  }

  animate() {
    const time = this.clock.getElapsedTime();

    const int = this.intensity;
    const sp = this.speed;

    this.flag.geometry.vertices.map(v => {
      const wave1 = -int * Math.cos(int * 3 * v.x + time * sp);
      const wave2 = int * Math.cos(int * 3 * v.y + time * sp);
      v.z = wave1 + wave2
    });

    this.flag.geometry.verticesNeedUpdate = true;

    this.flag.rotation.set(this.xAxis, this.yAxis, 0);

    if (!this.stopAnimate) {
      requestAnimationFrame(this.animate.bind(this))
    }
    this.renderer.render(this.scene, this.camera)
  }


}
