import OverlayScrollbars from 'overlayscrollbars';
import 'overlayscrollbars/css/OverlayScrollbars.css';


export default class CustomScroll {

   constructor() {
   
      var instances = OverlayScrollbars(document.querySelectorAll(".custom-scroll"), {
         scrollbars : {
           visibility: 'auto',
           autoHide: 'move',
           autoHideDelay: 500,
         },
       });
      
   
   }
   
}
