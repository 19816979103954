import noUiSlider from "nouislider";
import html2canvas from "html2canvas";
import axios from 'axios';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
import Plyr from "plyr";

window.imageHoverMove = function(e) {
  e.currentTarget.parentNode.querySelector('img').style.left = e.pageX+"px";
  e.currentTarget.parentNode.querySelector('img').style.top =e.pageY+"px";
}

window.imageHoverEnter = function(e){
  e.currentTarget.parentNode.querySelector('img').src = e.currentTarget.parentNode.querySelector('img').dataset.src;
  //e.currentTarget.parentNode.querySelector('img').classList.remove('hidden');
  gsap.to(e.currentTarget.parentNode.querySelector('img'), .2, {scale:1, autoAlpha:1});
}


window.imageHoverLeave = function(e){
  gsap.to(e.currentTarget.parentNode.querySelector('img'), .2, {scale:0.7, opacity:0});
}

window.cart = function(){
  return {
    cart : 0,
    fetch(){
      axios({
        url: '/api/cart'
      }).then(function (response) {
        this.cart = response.data.cart;
      }.bind(this));
    },
  }
}

window.live = function(background){
  return {
    live:false,
    id:null,
    provider:null,
    player:null,
    thumbnailPlayer:null,
    member_name:null,
    member_title:null,
    next_member_name:null,
    next_member_title:null,
    next_member_date:null,
    next_member_image:null,
    background: background,
    disabled:false,

    init(){
      this.fetch();
    },

    fetch(){
      axios({
        url: '/api/live'
      }).then(function (response) {

        if (this.id != response.data.id){
          setTimeout(function(){
            this.updateLive();
          }.bind(this),100);
        }

        setTimeout(function(){
          this.fetch();
        }.bind(this),10000);

        this.live = response.data.live;
        this.id = response.data.id;
        this.provider = response.data.provider;
        this.member_name = response.data.member_name;
        this.member_title = response.data.member_title;


        this.next_member_name = response.data.next_member_name;
        this.next_member_title = response.data.next_member_title;
        this.next_member_date = response.data.next_member_date;
        this.next_member_image = response.data.next_member_image;

      }.bind(this));
    },

    updateLive(){
      let target = '#live-player';
      if (this.background){
        target = '#live-thumbnail-player';
      }
      if (this.provider != 'vimeo') {
        if (!this.player) {
          this.player = new Plyr(target, {
            autoplay: true,
            muted: this.background,
            controls: [],
            hideControls: true,
            clickToPlay: false,
            ratio: '16:9'
          });
          this.player.on('ready', event => {
            let player = event.detail.plyr;
            player.play();
          });
        } else {
          //Update source
          this.player.source = {
            type: 'video',
            sources: [
              {
                src: this.id,
                provider: this.provider,
              },
            ],
          };
        }
      }


    }
  }
}

window.smoothScroll = function(target) {
  let to = target.srcElement.dataset.scrollto ? target.srcElement.dataset.scrollto : target.srcElement.hash;
  gsap.to(window, {duration: 2, scrollTo:to});
}

window.player = function(videoId) {
  return {
    id: videoId,
    launchVideo: function() {
      if(!window.modalPlayer) {
        window.modalPlayer = new Plyr('#modal-player');
        window.modalPlayer.autoplay = true;
      } else {
        window.modalPlayer.source = {
          type: 'video',
          sources: [
            {
              src: videoId,
              provider: 'vimeo',
            },
          ],
        };
      }
    }
  }
}


window.posterGenerator = function(members) {

  return {
    members: members,
    step:0,
    selectedMember:null,
    loading:false,
    token:null,
    url:null,
    image:null,
    filename:null,

    init(){
      const intensitySlider = document.getElementById('intensitySlider');
      const XAxisSlider = document.getElementById('XAxisSlider');
      const YAxisSlider = document.getElementById('YAxisSlider');

      noUiSlider.create(intensitySlider, {
        start: [0.2],
        connect: [true, false],
        range: {
          'min': [0.01],
          'max': [0.3]
        }
      });

      intensitySlider.noUiSlider.on('update', function () {
        window.poster.intensity = intensitySlider.noUiSlider.get();
      });

      noUiSlider.create(XAxisSlider, {
        start: [0],
        connect: [true, false],
        range: {
          'min': [-0.5],
          'max': [0.5]
        }
      });

      XAxisSlider.noUiSlider.on('update', function () {
        window.poster.xAxis = XAxisSlider.noUiSlider.get();
      });

      noUiSlider.create(YAxisSlider, {
        start: [0],
        connect: [true, false],
        range: {
          'min': [-0.5],
          'max': [0.5]
        }
      });

      YAxisSlider.noUiSlider.on('update', function () {
        window.poster.yAxis = YAxisSlider.noUiSlider.get();
      });

      setTimeout(function(){

      },2000);


    },
    load(selectedMember){
      this.selectedMember = selectedMember;
      window.poster.changeCover(selectedMember.cover);
    },

    resetSteps(){
      this.step = 0;
    },

    nextStep(){
      this.step++;
      if (this.step == 2){
        this.loading = true;
        return html2canvas(document.querySelector('#poster-container'),{width:window.poster.width, height:window.poster.height, scale:3, backgroundColor:'#000000'}).then(function(canvas) {
          canvas.toBlob(function(blob){

            const fd = new FormData();
            fd.append('fname', 'image.jpg');
            fd.append('data', blob);
            fd.append('token', this.token);
            fd.append('i', window.poster.intensity);
            fd.append('x', window.poster.xAxis);
            fd.append('y', window.poster.yAxis);
            fd.append('member', this.selectedMember ? this.selectedMember.id : null);

            axios({
              method:'post',
              url: '/poster/store',
              data: fd,
              headers: {'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
              //handle success
              console.log(response);
              this.loading = false;
              this.image = response.data.image;
              this.filename = response.data.filename;
              this.url = response.data.url;
              this.token = response.data.token;
            }.bind(this));


          }.bind(this), 'image/jpeg', 0.95)
        }.bind(this));
      }
    }
  }
}
