import Highway from '@dogstudio/highway';
import TextAppear from "../../textappear";
import WelcomeLoading from "../../welcome-loading";

export default class CommerceFormRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    TextAppear.init();
  }
  onLeave() {

  }
  onEnterCompleted() {

    if(WelcomeLoading.pagesLoader || WelcomeLoading.enterLoader) {
      if (!WelcomeLoading.pagesLoader.isPaused || !WelcomeLoading.enterLoader.isPaused){
        setTimeout(function(){
          this.onEnterCompleted();
        }.bind(this), 100);
        return null;
      }
    }

    TextAppear.observe();

  }
  onLeaveCompleted() {

  }
}
