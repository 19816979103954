import Highway from '@dogstudio/highway';
import Poster from '../../poster';

export default class PosterShareRenderer extends Highway.Renderer {
  // Hooks/methods

  onEnter() {
    if (!window.poster.init){
      window.poster = new Poster();
    }
    window.poster.resize();
    var elem = document.getElementById("header");
    elem.classList.add('header-hide');
    var elem2 = document.getElementById("footer");
    elem2.classList.add('footer-hide');
  }
  onLeave() {

  }
  onEnterCompleted() {
    window.poster.start();
    window.poster.xAxis = document.getElementById('poster').dataset.x;
    window.poster.yAxis = document.getElementById('poster').dataset.y;
    window.poster.intensity = document.getElementById('poster').dataset.intensity;
    window.poster.changeCover(document.getElementById('poster').dataset.cover);
  }
  onLeaveCompleted() {
    var elem = document.getElementById("header");
    elem.classList.remove('header-hide');
    var elem2 = document.getElementById("footer");
    elem2.classList.remove('footer-hide');
    window.poster.stop();
  }
}
