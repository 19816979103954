import Highway from '@dogstudio/highway';
import Poster from '../../poster';
import CustomScroll from "../../customScroll";

export default class PosterRenderer extends Highway.Renderer {
  // Hooks/methods

  onEnter() {


    new CustomScroll();
    if (!window.poster.init){
      window.poster = new Poster();
    }
    window.poster.resize();
  }
  onLeave() {
    window.poster.stop();
  }
  onEnterCompleted() {
    document.querySelector('#members').classList.remove('opacity-0');
    document.querySelector('#next-button').classList.remove('opacity-0');

    window.poster.start();
  }
  onLeaveCompleted() {

  }
}
