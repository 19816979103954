import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class ClosePoster extends Highway.Transition {

  out({ from, trigger, done }) {
    done();
  }

  in({ from, to, trigger, done }) {

    gsap.to(from.querySelector('#steps'),  {ease: "expo.out", duration: .2, opacity: 0 });

    gsap.to(from.querySelector('#poster-bg-dark'),  {ease: "expo.out", duration: .3, delay:.2, width: 0});
    gsap.to(from.querySelector('#poster-bg-black'),  { ease: "expo" , duration: .3,  delay:.5, width: 0 , onComplete:function(){
        from.remove();
        done();
      }});

    gsap.to(from.querySelector('#poster'), {ease: "expo.out", duration: .2, delay:0, opacity: 0 });

    //from.remove();
   // done();

  }

}
