import Highway from '@dogstudio/highway';
import { gsap } from "gsap";
import TextAppear from "../../textappear";
import SwiperInit from "../../swiperInit";
import Plyr from "plyr";
import WelcomeLoading from "../../welcome-loading";

export default class LiveReplayRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    TextAppear.init();
    const players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));
  }
  onLeave() {
  }
  onEnterCompleted() {

    if(WelcomeLoading.pagesLoader || WelcomeLoading.enterLoader) {
      if (!WelcomeLoading.pagesLoader.isPaused || !WelcomeLoading.enterLoader.isPaused){
        setTimeout(function(){
          this.onEnterCompleted();
        }.bind(this), 100);
        return null;
      }
    }

    TextAppear.observe();
    gsap.to('#live-thumb', {duration: 1, ease: "expo.out", xPercent: 100, onComplete:function(){
        let liveFull = new CustomEvent('liveevent', {
          detail: { liveFull: true }
        });
        window.dispatchEvent(liveFull);

    }});
  }
  onLeaveCompleted() {
    let liveFull = new CustomEvent('liveevent', {
      detail: { liveFull: false }
    });
    window.dispatchEvent(liveFull);
    gsap.to('#live-thumb', {duration: 1, ease: "expo.out", xPercent: 0});
  }
}
