import Highway from '@dogstudio/highway';
import { gsap } from "gsap";
import { Power4 } from "gsap";
import WelcomeLoading from "../../welcome-loading";

export default class Slide extends Highway.Transition {

  out({ from, trigger, done }) {

    if(WelcomeLoading.pagesLoader) {
      WelcomeLoading.pagesLoader.play();
      gsap.to(document.getElementById('js-welcome-loading'), {
        scaleY: 1,
        duration: 1,
        ease: Power4.easeInOut,
        onComplete: function() {
          document.getElementById('js-welcome-loading').classList.remove('origin-bottom');
          document.getElementById('js-welcome-loading').classList.add('origin-top');
          done();
        }
      });
    } else {
      done();
    }
  }

  in({ from, to, trigger, done }) {

    from.remove();
    window.scrollTo(0, 0);

    if(WelcomeLoading.pagesLoader) {
      gsap.to(document.getElementById('js-welcome-loading'), {
        scaleY: 0,
        duration: 1,
        ease: Power4.easeInOut,
        onComplete: function() {
          document.getElementById('js-welcome-loading').classList.remove('origin-top');
          document.getElementById('js-welcome-loading').classList.add('origin-bottom');
          WelcomeLoading.pagesLoader.stop();
          done();
        }
      });
    } else {
      done();
    }

  }

}
