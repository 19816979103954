import './scss/app.scss';
import 'alpine-magic-helpers'
import 'alpinejs';

import AdminTabs from "./js/adminTabs";
import CookieAgreed from "./js/cookieAgreed";
import Highway from '@dogstudio/highway';
import Plyr from 'plyr';
import { listen, prefetch } from "quicklink";
import WelcomeLoading from "./js/welcome-loading";
import { gsap } from "gsap";
import { Power4 } from "gsap";

// Renderers
import DefaultRenderer from "./js/highway/renderer/default-renderer";
import ProgramRenderer from "./js/highway/renderer/program-renderer";
import LiveReplayRenderer from "./js/highway/renderer/live-replay-renderer";
import CommerceFormRenderer from "./js/highway/renderer/commerce-form-renderer";

//Transitions
import Slide from "./js/highway/transition/slide";

//Admin
new AdminTabs();

import * as FastClick from 'fastclick';
FastClick['attach'](document.body);

//GDPR
document.addEventListener('consent-given', function(){
  console.log('consent given');
  //Enable matomo or google analytics here ?
});
new CookieAgreed();

// Misc
import Alpine from './js/alpine';
import OpenPoster from "./js/highway/transition/openPoster";
import ClosePoster from "./js/highway/transition/closePoster";
import PosterRenderer from "./js/highway/renderer/poster-renderer";
import PosterShareRenderer from "./js/highway/renderer/poster-share-renderer";

/***
 * Highway
 */

// Call Highway.Core once.
// Store it in a variable to use events
const H = new Highway.Core({
    renderers: {
      homepage: DefaultRenderer,
      liveReplays: LiveReplayRenderer,
      program: ProgramRenderer,
      memberpage: DefaultRenderer,
      shoppage: DefaultRenderer,
      checkout: DefaultRenderer,
      cartempty: DefaultRenderer,
      cartcontent: DefaultRenderer,
      productfull: DefaultRenderer,
      default: DefaultRenderer,
      commerceForm: CommerceFormRenderer,
      poster: PosterRenderer,
      postershare: PosterShareRenderer
    },
    transitions: {
      default: Slide,
      contextual: {
        openPoster: OpenPoster,
        closePoster: ClosePoster,
      },
    }
  }
);

/*H.detach(
  document.getElementById('admin-tools').querySelectorAll('a')
)*/

// Listen Events
const updateLinks = function(){
  const links = document.querySelectorAll('[role="navigation"] a');
  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    link.classList.remove('is-active');

    if (location.href.indexOf(link.href) > -1) {
      link.classList.add('is-active');
    }
  }
}

const updateOverflow = function() {
  if (document.body.classList.contains('overflow-y-hidden')) {
    document.body.classList.remove('overflow-y-hidden');
  }
}

// Welcome loader
if(!document.getElementById('admin-tools')) {
  new WelcomeLoading();
}

H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
  updateLinks();
  updateOverflow();

  // Analytics
  if (typeof gtag !== 'undefined') {
    // eslint-disable-next-line
    gtag('js', new Date());
    gtag('config', 'UA-45857777-22', {
      'page_path': location.pathname,
      'page_title': to.page.title,
      'page_location': location.href
    });
  }

  var menuopenevent = new CustomEvent('menuopenevent', {
      detail: { isMenuOpen: false }
  });
  window.dispatchEvent(menuopenevent);

});
updateLinks();

H.on('NAVIGATE_OUT', ({ to, trigger, location }) => {
  if (document.getElementById('admin-tools')){
    window.location.reload();
  }
});
