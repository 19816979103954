import { Swiper, Navigation, Lazy } from "swiper";
Swiper.use([Swiper, Navigation, Lazy]);

export default class SwiperInit {
  constructor() {


    var element =  document.querySelector('.swiper-container');
    if (typeof(element) != 'undefined' && element != null) {
      var swiper = new Swiper('.swiper-container', {
        direction: 'horizontal',
        loop: true,
        spaceBetween: 32,
        preloadImages: false,
        lazy: {
          loadPrevNext: true
        },
        grabCursor: true,
        watchSlidesVisibility: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      });
      swiper.el.style.overflow = "visible";
    }

    var shopSwiper =  document.querySelector('.shop-swiper');
    if (typeof(shopSwiper) != 'undefined' && shopSwiper != null) {
      var shopSwiperInit = new Swiper(shopSwiper, {
        direction: 'horizontal',
        loop: true,
        grabCursor: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
      })
    }
    

  }

}
