import Highway from '@dogstudio/highway';
import { gsap } from "gsap";
import TextAppear from "../../textappear";
import WelcomeLoading from "../../welcome-loading";

export default class ProgramRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    TextAppear.init();
  }
  onLeave() {
  }
  onEnterCompleted() {

    if(WelcomeLoading.pagesLoader || WelcomeLoading.enterLoader) {
      if (!WelcomeLoading.pagesLoader.isPaused || !WelcomeLoading.enterLoader.isPaused){
        setTimeout(function(){
          this.onEnterCompleted();
        }.bind(this), 100);
        return null;
      }
    }

    TextAppear.observe();

    let programDates = document.getElementById('program-dates');
    let programSections = document.getElementById('program-section');
    let programDatesClone = programDates.cloneNode(true);
    programDatesClone.id = "program-section-cloned";
    programDatesClone.classList.remove('relative');
    programDatesClone.classList.add('fixed', 'left-0', 'top-0', 'right-0', 'bg-gradient-to-b', 'from-black', 'pt-16', 'z-20', 'transform', '-translate-y-full');
    document.body.append(programDatesClone);

    window.onscroll = function() {
      if(
        (programDates.getBoundingClientRect().y < -(programDates.getBoundingClientRect().height))
        &&
        (programSections.getBoundingClientRect().y > -(programSections.getBoundingClientRect().height))
      ) {
        gsap.to(programDatesClone, {opacity:100, y: 0, duration: .5});
      } else {
        gsap.to(programDatesClone, {opacity:0, y: '-100%', duration: .5});
      }
    }

  }
  onLeaveCompleted() {
    document.getElementById('program-section-cloned').remove();
  }
}
