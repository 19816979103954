import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class OpenPoster extends Highway.Transition {

  out({ from, trigger, done }) {
    console.log('out');
    if (from.querySelector('#poster-container')){
      from.querySelector('#poster-container').remove();
    }

    done();
  }

  in({ from, to, trigger, done }) {


    gsap.fromTo(to.querySelector('#poster-bg-black'), {left: "-100%"},  { ease: "expo" , duration: .3, left: 0 });
    gsap.fromTo(to.querySelector('#poster-bg-dark'), {width: 0}, {ease: "expo.out", duration: .3, delay:.3, width: '50%' });

    gsap.fromTo(to.querySelector('#poster-container'), {opacity: 0}, {ease: "expo.out", duration: 3, delay:.4, opacity: 1 });

    gsap.fromTo(to.querySelector('#poster-header'), {opacity: 0, y:-50}, {ease: "expo.out", duration: .2, delay:.6, opacity: 1, y:0 });



    setTimeout(function(){
      gsap.fromTo(to.querySelector('#next-button'), {opacity: 0}, {ease: "expo", duration: .3,  opacity: 1 });
      if (to.querySelector('#next-button')) to.querySelector('#next-button').classList.remove('opacity-0');
    },600);


    setTimeout(function(){
      gsap.fromTo(to.querySelectorAll('.member'), {opacity: 0}, {ease: "expo.out", duration: .2, delay:0, opacity: 1, onComplete:function(){
          from.remove();
          done();
        }, stagger:.025 });
        if (to.querySelector('#members')) to.querySelector('#members').classList.remove('opacity-0');
    }, 700);

  }

}
