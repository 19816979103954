import Highway from '@dogstudio/highway';
import TextAppear from "../../textappear";
import BlockAppear from "../../blockappear";
import SwiperInit from "../../swiperInit";
import Plyr from "plyr";
import WelcomeLoading from "../../welcome-loading";
import { gsap } from "gsap";
import { Power4 } from "gsap";

export default class DefaultRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    TextAppear.init();
    BlockAppear.init();
    new SwiperInit();
    const players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));

    const pplayers = Array.from(document.querySelectorAll('.js-paragraph-player')).map(p => new Plyr(p, {
      'loop': {active:true},
      'controls' :['play-large', 'play','mute','volume', 'fullscreen']
    }));


  }
  onLeave() {

  }
  onEnterCompleted() {

    if(WelcomeLoading.pagesLoader || WelcomeLoading.enterLoader) {
      if (!WelcomeLoading.pagesLoader.isPaused || !WelcomeLoading.enterLoader.isPaused){
        setTimeout(function(){
          this.onEnterCompleted();
        }.bind(this), 100);
        return null;
      }
    }

    TextAppear.observe();
    BlockAppear.observe();
  }
  onLeaveCompleted() {

  }
}
